const getPlainTxt = (html) => {
  html = html.replace(/[\n\r]/g, '')
  const htmlText = html.replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, '\n').replace(/<br\/?>/gi, '\n').replace(/<[^>/]+>/g, '').replace(/(\n)?<\/([^>]+)>/g)
  const h = htmlText.replace(/\u00a0/g, '').replace(/&nbsp;/g, '').replace(/undefined/g, '')
  return h
}

module.exports = {
  getPlainTxt
}
